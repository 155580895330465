import * as React from "react"
import MindCandy from "../components/sections/mind-candy"
import { UserProvider } from "../components/utils/UserContext"

const MCPreview = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const resourceVersion = params.get("resourceVersion")
  const vidQuery = resourceVersion
    ? { resourceVersion: parseInt(resourceVersion) }
    : {}
  const queryValues = {
    drupalId: params.get("drupalId"),
    ...vidQuery,
  }
  return (
    <>
      <main className="mt-6 grid grid-cols-1 gap-5 px-3 dark:bg-black dark:text-white md:px-10">
        <h1 className="mb-2 text-xl font-semibold">Mind Candy Preview</h1>
        <UserProvider>
          <MindCandy queryVariables={queryValues} />
        </UserProvider>
      </main>
    </>
  )
}

export default MCPreview
